import { ReactComponent as Contributors } from 'images/contributors.svg'
import { ReactComponent as CreateAccount } from 'images/create_account.svg'
import { ReactComponent as Tags } from 'images/tags.svg'
import { ReactComponent as Messages } from 'images/messages.svg'
import { ReactComponent as Reservations } from 'images/reservations.svg'
import { ReactComponent as Bots } from 'images/bots.svg'
import { ReactComponent as Letters } from 'images/letters.svg'
import { ReactComponent as Analytics } from 'images/analytics.svg'
import { ReactComponent as Accounts } from 'images/accounts.svg'
import { ContributorRoute, ManagementRoute, Route } from 'models/routes'
import { Contributor } from 'models/contributors'

export const isRoutes = (arg: Array<Route | undefined>): arg is Route[] =>
  arg !== undefined

const baseContributorRoutes: ContributorRoute[] = [
  {
    label: 'メッセージ配信',
    icon: Messages,
    path: 'messages',
    accordion: [],
    spOnly: false,
    single: true,
  },
  {
    label: '予約配信',
    icon: Reservations,
    path: 'reserved_messages',
    accordion: [],
    spOnly: true,
    single: true,
  },
  {
    label: 'イベント bot 配信',
    icon: Bots,
    path: 'bot_messages',
    accordion: [],
    spOnly: false,
    single: true,
  },
  {
    label: 'マニュアル bot 管理',
    icon: Bots,
    path: 'manual_bot_messages',
    accordion: [],
    spOnly: false,
    single: true,
  },
  {
    label: 'アナリティクス',
    icon: Analytics,
    path: 'analysis',
    accordion: [],
    spOnly: false,
    single: true,
  },
  {
    label: 'プレミアムチャット',
    icon: Letters,
    path: 'direct_messages',
    accordion: [],
    spOnly: false,
    single: true,
  },
  {
    label: 'メンバーシップ',
    icon: Contributors,
    path: 'subscription_tiers',
    accordion: [],
    spOnly: false,
    single: true,
  },
  {
    label: '通話スケジュール',
    icon: Contributors,
    path: 'meeting_schedules',
    accordion: [],
    spOnly: false,
    single: true,
  },
  {
    label: 'ボイスコンテンツ',
    icon: Contributors,
    path: 'voice_contents',
    accordion: [],
    spOnly: false,
    single: true,
  },
  {
    label: '利用規約等',
    icon: Contributors,
    path: 'documents',
    accordion: [],
    spOnly: false,
    single: true,
  },
  {
    label: 'アカウント設定',
    icon: Accounts,
    accordion: [
      {
        label: 'プロフィール',
        path: 'profile',
      },
      {
        label: 'アカウント情報',
        path: 'account',
      },
    ],
    spOnly: false,
    single: true,
  },
]

const baseManagementRoutes = [
  {
    label: '配信者リスト',
    icon: Contributors,
    path: '/contributors',
    accordion: [],
    spOnly: false,
    admin: true,
  },
  {
    label: '新規作成',
    icon: CreateAccount,
    path: '/contributors/new',
    accordion: [],
    spOnly: false,
    admin: true,
  },
  {
    label: 'タグリスト',
    icon: Tags,
    path: '/tags',
    accordion: [],
    spOnly: false,
    admin: true,
  },
  {
    label: 'アナリティクス',
    icon: Analytics,
    path: '/analysis',
    accordion: [],
    spOnly: false,
    admin: true,
  },
  {
    label: 'バッチリスト',
    icon: CreateAccount,
    path: '/batches',
    accordion: [],
    spOnly: false,
    admin: true,
  },
  {
    label: 'メンテナンス管理',
    icon: CreateAccount,
    path: '/maintenance_windows',
    accordion: [],
    spOnly: false,
    admin: true,
  },
  {
    label: 'バージョン管理',
    icon: CreateAccount,
    path: '/platforms',
    accordion: [],
    spOnly: false,
    admin: true,
  },
  {
    label: '財務レポート管理',
    icon: CreateAccount,
    path: '/financial_reports',
    accordion: [],
    spOnly: false,
    admin: true,
  },
]

export const composeRoutes = (
  auth: string,
  type: string,
  contributorId?: number
): Route[] => {
  let routes: Array<Route | undefined> = []

  switch (auth) {
    case 'Administrator':
      if (type === 'management') {
        routes = baseManagementRoutes
          .map((route: ManagementRoute) => {
            if (route.admin) {
              return {
                label: route.label,
                icon: route.icon,
                path: route.path,
                accordion: route.accordion,
                spOnly: route.spOnly,
              }
            }
          })
          .filter((route?: Route) => route)
      } else if (type === 'contributor_single') {
        routes = baseContributorRoutes
          .map((route: ContributorRoute) => {
            if (route.single) {
              return {
                label: route.label,
                icon: route.icon,
                path:
                  route.path && `/contributors/${contributorId}/${route.path}`,
                accordion: route.accordion.map(
                  (item: { label: string; path: string }) => ({
                    ...item,
                    path: `/contributors/${contributorId}/${item.path}`,
                  })
                ),
                spOnly: route.spOnly,
              }
            }
          })
          .filter((route?: Route) => route)
      }
      break
    case 'Contributor':
      if (type === 'contributor_single') {
        routes = baseContributorRoutes
          .map((route: ContributorRoute) => {
            return {
              label: route.label,
              icon: route.icon,
              path:
                route.path && `/contributors/${contributorId}/${route.path}`,
              accordion: route.accordion.map(
                (item: { label: string; path: string }) => ({
                  ...item,
                  path: `/contributors/${contributorId}/${item.path}`,
                })
              ),
              spOnly: route.spOnly,
            }
          })
          .filter((route?: Route) => route)
      }
      break
  }

  if (isRoutes(routes)) return routes

  return []
}

export const isContributorPath = (path: string, contributor: Contributor) => {
  if (
    (path.match(/contributors\/\d+\/messages$/) ||
      path.match(/contributors\/\d+\/reserved_messages$/) ||
      path.match(/contributors\/\d+\/messages\/\d+/) ||
      path.match(/contributors\/\d+\/new_message$/) ||
      path.match(/contributors\/\d+\/message\/\d+\/edit$/) ||
      path.match(/contributors\/\d+\/bot_messages$/) ||
      path.match(/contributors\/\d+\/new_bot_message$/) ||
      path.match(/contributors\/\d+\/profile$/) ||
      path.match(/contributors\/\d+\/profile\/edit$/) ||
      path.match(/contributors\/\d+\/account$/) ||
      path.match(/contributors\/\d+\/bot_messages$/) ||
      path.match(/contributors\/\d+\/bot_messages\/\d+$/) ||
      path.match(/contributors\/\d+\/manual_bot_messages$/) ||
      path.match(/contributors\/\d+\/manual_bot_messages\/new$/) ||
      path.match(/contributors\/\d+\/manual_bot_messages\/\d+$/) ||
      path.match(/contributors\/\d+\/analysis$/) ||
      path.match(/contributors\/\d+\/direct_messages$/) ||
      path.match(/contributors\/\d+\/direct_messages\/\d+$/) ||
      path.match(/contributors\/\d+\/subscription_tiers$/) ||
      path.match(/contributors\/\d+\/subscription_tiers\/\d+$/) ||
      path.match(
        /contributors\/\d+\/subscription_tiers\/\d+\/memberships\/\d+$/
      ) ||
      path.match(/contributors\/\d+\/subscription_tiers\/\d+\/edit$/) ||
      path.match(/contributors\/\d+\/membership_birthdays$/) ||
      path.match(/contributors\/\d+\/meeting_schedules$/) ||
      path.match(/contributors\/\d+\/meeting_schedules\/new$/) ||
      path.match(/contributors\/\d+\/meeting_tickets\/\d+$/) ||
      path.match(/contributors\/\d+\/meeting_reservations$/) ||
      path.match(/contributors\/\d+\/voice_contents$/) ||
      path.match(/contributors\/\d+\/voice_contents\/\d+$/) ||
      path.match(/contributors\/\d+\/voice_contents\/new$/) ||
      path.match(/contributors\/\d+\/voice_contents\/\d+\/edit$/) ||
      path.match(/contributors\/\d+\/documents$/)) &&
    contributor
  )
    return true

  return false
}
