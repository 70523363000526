import { getContributor } from 'actions/contributors'
import ItemRow from 'components/account_profiles/detail/components/item_row'
import MainBody from 'components/modules/main_body'
import MainHeader from 'components/modules/main_header'
import { useContributor } from 'hooks/useContributor'
import { CombineReducers } from 'models'
import { ContributorsState } from 'models/contributors'
import { SessionsState } from 'models/sessions'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

export default function DocumentsList() {
  const params = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const sessionsState: SessionsState = useSelector((state: CombineReducers) => {
    return state.sessions
  })

  const [user, contributor] = useContributor({ queryString: params })

  useEffect(() => {
    if (sessionsState.currentUser) {
      dispatch(
        getContributor.started({
          id:
            sessionsState.currentUser.authType === 'Administrator'
              ? parseInt(params.id)
              : sessionsState.currentUser.contributor.id,
        })
      )
    }
  }, [sessionsState.status.succeededGetCurrentUser])

  if (!contributor) return <></>

  return (
    <>
      <MainHeader heading={'利用規約等'} />
      <MainBody>
        <ItemRow
          label={'利用規約'}
          link={{
            name: 'https://dearst.notion.site/1123ca5882138071b64feafae1a75362',
            path: 'https://dearst.notion.site/1123ca5882138071b64feafae1a75362',
          }}
        />
        <ItemRow
          label={'プライバシーポリシー'}
          link={{
            name: 'https://lp.mydear.anystyle.jp/privacy_policy',
            path: 'https://lp.mydear.anystyle.jp/privacy_policy',
          }}
        />
        {contributor.affiliation === 'nest' && (
          <ItemRow
            label={'報酬規定'}
            link={{
              name: 'https://dearst.notion.site/1123ca58821380a5af15cb5303f3ce71',
              path: 'https://dearst.notion.site/1123ca58821380a5af15cb5303f3ce71',
            }}
          />
        )}
      </MainBody>
    </>
  )
}
