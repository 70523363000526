import tagsDeserializer from 'deserializers/tags/many'
import { Contributor } from 'models/contributors'
import xAccountDeserializer from 'deserializers/x_account'
import botMessagesDeserializer from 'deserializers/event_bot_messages'
import contributorProfilesDeserializer from 'deserializers/contributor_profiles/many'

function contributorDeserializer(input: {
  data: any
  meta?: any
}): Contributor {
  return {
    id: input.data?.id,
    icon: input.data?.icon,
    name: input.data?.name,
    englishName: input.data?.english_name,
    hiraganaName: input.data?.hiragana_name,
    katakanaName: input.data?.katakana_name,
    email: input.data?.email,
    description: input.data?.description,
    profiles: contributorProfilesDeserializer({
      data: input.data?.contributor_profiles
        ? input.data.contributor_profiles
        : [],
    }),
    twitterLink: input.data?.twitter_link,
    youtubeLink: input.data?.youtube_link,
    tags: tagsDeserializer({ data: input.data?.tags ? input.data.tags : [] }),
    subscribersCount: input.data?.subscribers_count,
    productId: input.data?.product_id,
    isApproved: input.data?.is_approved,
    xAccount: xAccountDeserializer({ data: input.data?.contributor_x_account }),
    discordWebhookUrl: input.data?.discord_webhook_url,
    discordUserId: input.data?.discord_user_id,
    botMessages: botMessagesDeserializer({
      data: input.data?.bot_messages ? input.data.bot_messages : [],
    }),
    voiceCall: input.data?.voice_call,
    approvedAt: input.data?.approved_at,
    affiliation: input.data?.affiliation,
  }
}

export default contributorDeserializer
